<template>
  <div class="card mb-lg-0" id="commentWrite">
    <div class="card-header">
      <h3 class="card-title">Değerlendirme</h3>
    </div>
    <div class="card-body">
      <template v-if="$store.state.isLogin">
        <div class="form-group">
          <textarea
            class="form-control"
            name="comment"
            rows="6"
            placeholder="Değerlendirmenizi yazın"
          ></textarea>
        </div>
        <button
          @click="sendComment"
          id="sendButton"
          class="btn btn-primary w-100"
        >
          Gönder
        </button>
      </template>
      <template v-else>
        <router-link to="/login" class="btn btn-primary"
          >Görüş yapabilmek için Giriş Yapın</router-link
        >
      </template>
    </div>
  </div>
</template>
<script>
export default {
  created() {},
  methods: {
    sendComment() {
      document.getElementById("sendButton").disabled = true;
      document.getElementById("sendButton").innerHTML =
        "Değerlendirmeniz gönderiliyor..";
      let comment = document.querySelector("textarea[name=comment]").value;
      if (comment == null) {
        this.$vToastify.warning("Değerlendirme alanı boş bırakılamaz", "Uyarı!");
      } else {
        let commentSave = {
          education_id: this.$route.params.id,
          comment: comment,
          parent_id:0
        };
        this.$store.dispatch("getEducationCommentSend", commentSave).then((value) => {
          if (value.type == "error") {
            if (value.message == "ERRxNCYS") {
              this.$vToastify.warning("Kendi profiline değerlendirme yapamazsın!", "Uyarı!");
            }
          }
          if (value.type == "success") {
            if (value.message == "SCCxCWS") {
              this.$vToastify.success(
                "Görüş başarılı bir şekilde eklendi",
                "Başarılı!"
              );
              let commentGet = {
                authUserId: this.$store.state.isLogin ? this.$store.state.user_id : 0,
                education_id: this.$route.params.id,
                begin: 0,
                perpage: 20,
              };
              this.$store.dispatch("getEducationComment", commentGet).then((value) => {
                this.$parent.list = value.list;
              });
            }
          }
          document.querySelector("textarea[name=comment]").value=""
          document.querySelector("textarea[name=comment]").disabled=true
          document.getElementById("sendButton").disabled = true;
          document.getElementById("sendButton").innerHTML = "Gönder";
        });
      }
    },
  },
};
</script>