<template>
  <div class="card" v-if="list.length>0">
    <div class="card-header">
      <h3 class="card-title">Değerlendirme</h3>
    </div>
    <div class="card-body p-0">
      <div
        class="media mt-0 p-5"
        v-for="(item, i) in list"
        :key="'comment' + i"
      >
        <div
          class="d-flex me-3"
          :style="item.approved == 0 ? 'opacity:0.5' : ''"
        >
          <template v-if="item.profile_image_url != null">
            <lazy-image
              class="media-object brround"
              alt="64x64"
              :src="
                'https://storage.terapivitrini.com/' + item.profile_image_url
              "
          /></template>
          <template v-else>
            <lazy-image
              class="media-object brround"
              alt="64x64"
              src="https://storage.terapivitrini.com/storage/files/user_profile.jpg"
          /></template>
        </div>
        <div class="media-body">
          <h5 class="mt-0 mb-1 font-weight-semibold">
            {{ item.fullname }}
            <br />
            <small style="font-size: 10px" v-if="item.approved == 0"
              >Bu görüşü yalnızca siz görüyorsunuz, onaylandığında herkes
              görebilecek</small
            >
          </h5>
          <small class="text-muted"
            ><i class="fa fa-calendar"></i> {{ item.created_at.split(" ")[0] }}
            <i class="ms-3 fa fa-clock-o"></i>
            {{ item.created_at.split(" ")[1] }}
          </small>
          <p class="font-13 mb-2 mt-2">
            {{ item.content }}
          </p>

          <template v-if="item.subComment.length > 0">
            <div
              class="media mt-0 p-5"
              v-for="(a, t) in item.subComment"
              :key="'comment' + t"
            >
              <div
                class="d-flex me-3"
                :style="a.approved == 0 ? 'opacity:0.5' : ''"
              >
                <template v-if="a.profile_image_url != null">
                  <lazy-image
                    class="media-object brround"
                    alt="64x64"
                    :src="
                      'https://storage.terapivitrini.com/' + a.profile_image_url
                    "
                /></template>
                <template v-else>
                  <lazy-image
                    class="media-object brround"
                    alt="64x64"
                    src="https://storage.terapivitrini.com/storage/files/user_profile.jpg"
                /></template>
              </div>
              <div class="media-body">
                <h5 class="mt-0 mb-1 font-weight-semibold">
                  {{ a.fullname }}
                </h5>
                <small class="text-muted"
                  ><i class="fa fa-calendar"></i>
                  {{ a.created_at.split(" ")[0] }}
                  <i class="ms-3 fa fa-clock-o"></i>
                  {{ a.created_at.split(" ")[1] }}
                </small>
                <p class="font-13 mb-2 mt-2">
                  {{ a.content }}
                </p>
              </div>
            </div>
          </template>
          <textarea
            v-if="
              item.subComment.length < 1 &&
              $store.state.isLogin &&
              $store.state.user_id == user_id
            "
            class="form-control mb-2"
            :placeholder="item.fullname + ' adlı kullanıcıya cevabınızı iletin'"
            :name="'commentAnswer' + i"
          ></textarea>
          <button
            :id="'sendButton' + i"
            v-if="
              item.subComment.length < 1 &&
              $store.state.isLogin &&
              $store.state.user_id == user_id
            "
            class="btn btn-primary"
            @click="sendAnswer(i, item.id)"
          >
            Cevapla
          </button>
        </div>
      </div>
      <button class="btn btn-primary w-100" @click="loadmore" v-if="isshow">
        <span v-if="!isloadcomment">Daha Fazla Yükle</span>
        <div v-if="isloadcomment" class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["user_id"],
  created() {
    let commentGet = {
      authUserId: this.$store.state.user_id,
      education_id: this.$route.params.id,
      begin: 0,
      perpage: this.currentload,
    };
    this.$store.dispatch("getEducationComment", commentGet).then((value) => {
      this.list = value.list;
    });
  },
  watch: {
    "$store.state.user_id": function () {
      let commentGet = {
        authUserId: this.$store.state.user_id,
        education_id: this.$route.params.id,
        begin: 0,
        perpage: this.currentload,
      };
      this.$store.dispatch("getEducationComment", commentGet).then((value) => {
        this.list = value.list;
      });
    },
  },
  data() {
    return {
      isloadcomment: false,
      isshow: true,
      list: [],
      currentload: 10,
      perpageload: 2,
    };
  },
  methods: {
    loadmore() {
      if (this.isloadcomment == false) {
        this.isloadcomment = true;
        let cl = this.currentload;
        let commentGet = {
          authUserId: this.$store.state.isLogin ? this.$store.state.user_id : 0,
          education_id: this.$route.params.id,
          begin: cl,
          perpage: this.perpageload,
        };
        this.currentload += this.perpageload;
        this.$store
          .dispatch("getEducationComment", commentGet)
          .then((value) => {
            if (value.list.length > 0) {
              let arr = value.list;
              arr.forEach((e) => {
                this.$store.state.commentList.push(e);
              });
              this.isloadcomment = false;
            } else {
              this.isshow = false;
            }
          });
      }
    },
    sendAnswer(i, id) {
      document.getElementById("sendButton" + i).disabled = true;
      document.getElementById("sendButton" + i).innerHTML =
        "Görüşünüz gönderiliyor..";
      let comment = document.querySelector(
        "textarea[name=commentAnswer" + i + "]"
      ).value;
      if (comment == null) {
        this.$vToastify.warning(
          "Değerlendirme alanı boş bırakılamaz",
          "Uyarı!"
        );
      } else {
        let commentSave = {
          education_id: this.$route.params.id,
          comment: comment,
          parent_id: id,
        };
        this.$store
          .dispatch("getEducationCommentSend", commentSave)
          .then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxNCYS") {
                this.$vToastify.warning(
                  "Kendi profiline değerlendirme yapamazsın!",
                  "Uyarı!"
                );
              }
            }
            if (value.type == "success") {
              if (value.message == "SCCxCWS") {
                this.$vToastify.success(
                  "Görüş başarılı bir şekilde eklendi",
                  "Başarılı!"
                );
                let commentGet = {
                  authUserId: this.$store.state.user_id,
                  education_id: this.$route.params.id,
                  begin: 0,
                  perpage: this.currentload,
                };
                this.$store
                  .dispatch("getEducationComment", commentGet)
                  .then((value) => {
                    this.list = value.list;
                  });
              }
            }
            document.querySelector("textarea[name=comment" + i + "]").value =
              "";
            document.querySelector(
              "textarea[name=comment" + i + "]"
            ).disabled = true;
            document.getElementById("sendButton" + i + "").disabled = true;
            document.getElementById("sendButton" + i + "").innerHTML = "Gönder";
          });
      }
    },
    sendComment() {},
  },
};
</script>