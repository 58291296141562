<template>
  <div>
    <Breadcrumb
      title="Eğitim Detay"
      :array="[
        { islink: true, url: '/', text: 'Ana Sayfa' },
        { islink: true, url: '/education', text: 'Eğitim' },
        { islink: false, url: '', text: education.title },
      ]"
    />
    <section class="sptb">
      <div class="container">
        <div class="row" v-if="load">
          <div class="col-xl-8 col-lg-8 col-md-12" id="promo_slider">
            <!--Education Description-->
            <div class="card">
              <div class="card-body h-100 primo-slider">
                <div class="item-det mb-4">
                  <a class="text-dark"
                    ><h1 style="font-size: 21px;font-weight: 500;" class="">{{ education.title }}</h1></a
                  >
                  <div class="d-flex">
                    <ul class="d-flex mb-0">
                      <li class="me-5">
                        <span class="icons"
                          ><i class="si si-briefcase text-muted me-1"></i
                          >{{ education.category }}</span
                        >
                      </li>
                      <li
                        class="me-5"
                        v-if="education.city != '' || education.district != ''"
                      >
                        <span class="icons"
                          ><i class="si si-location-pin text-muted me-1"></i
                          >{{ education.city }}/{{ education.district }}</span
                        >
                      </li>
                      <li class="me-5" v-if="education.education_type != 3">
                        <span class="icons"
                          ><i class="si si-calendar text-muted me-1"></i>
                          {{ education.begin_date }}</span
                        >
                      </li>
                      <li class="me-5">
                        <span class="icons"
                          ><i class="si si-eye text-muted me-1"></i>
                          {{ education.show }}</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="product-slider carousel-slide-2">
                  <div
                    id="carouselFade"
                    class="carousel slide carousel-fade"
                    data-bs-ride="carousel"
                    data-bs-loop="false"
                    data-bs-thumb="true"
                  >
                    <div
                      class="arrow-ribbon2 bg-primary"
                      style="font-size: 25px"
                    >
                      <template
                        v-if="
                          education.dumping_price &&
                          (education.dumping_price != 0 ||
                            education.dumping_price != null ||
                            education.dumping_price != '')
                        "
                      >
                        <del
                          style="font-size: 15px; position: absolute; top: -4px"
                          ><small>{{ education.price }}TL</small></del
                        >
                        <div style="margin-top: 5px">
                          {{ education.dumping_price }}TL
                        </div>
                      </template>
                      <template v-else> {{ education.price }}TL </template>
                    </div>
                    <div
                      class="carousel-inner slide-show-image"
                      id="full-gallery"
                    >
                      <template v-for="(item, i) in education.images">
                        <div
                          v-if="item.path != null"
                          :class="'carousel-item  ' + (i == 0 ? 'active' : '')"
                          :key="i"
                        >
                          <img
                            :src="
                              'https://storage.terapivitrini.com/' + item.path
                            "
                            alt="img"
                          />
                        </div>
                      </template>
                      <div class="thumbcarousel">
                        <a
                          class="carousel-control-prev"
                          href="#carouselFade"
                          role="button"
                          data-bs-slide="prev"
                        >
                          <i class="fa fa-angle-left" aria-hidden="true"></i>
                        </a>
                        <a
                          class="carousel-control-next"
                          href="#carouselFade"
                          role="button"
                          data-bs-slide="next"
                        >
                          <i class="fa fa-angle-right" aria-hidden="true"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row thumbimages">
                  <div
                    v-for="(item, i) in education.images"
                    :key="i"
                    class="col-md-6 col-xl-3 col-lg-6 mt-5"
                  >
                    <img
                      v-if="item.path != null"
                      :src="'https://storage.terapivitrini.com/' + item.path"
                      alt="img"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="card" >
              <div class="card-header">
                <h3 class="card-title">Açıklama</h3>
              </div>
              <div class="card-body">
                <p v-html="education.content"></p>
              </div>
            </div>
            <div class="card" id="description">
              <div class="card-header">
                <h3 class="card-title">Detay</h3>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12 col-md-12">
                    <div class="table-responsive">
                      <table
                        class="table row table-borderless w-100 m-0 text-nowrap"
                      >
                        <tbody class="col-lg-12 col-xl-6 p-0">
                          <tr>
                            <td>
                              <span class="font-weight-bold">Süre :</span>
                              {{ education.duration }} Saat
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="font-weight-bold">Ücret : </span>

                              <template v-if="education.dumping_price > 0">
                                <span
                                  >{{
                                    education.dumping_price
                                  }}TL&nbsp;&nbsp;</span
                                >
                                <span
                                  ><del>{{ education.price }}TL</del></span
                                >
                              </template>
                              <template v-else>
                                {{ education.price }}TL
                              </template>
                            </td>
                          </tr>
                          <tr v-if="education.education_type != 3">
                            <td>
                              <span class="font-weight-bold">Kontenjan:</span>
                              {{ education.participant_size }} Kişi
                            </td>
                          </tr>
                          <tr v-if="education.education_type != 3">
                            <td>
                              <span class="font-weight-bold">Başlangıç:</span>
                              {{ education.begin_date }}
                            </td>
                          </tr>
                        </tbody>
                        <tbody class="col-lg-12 col-xl-6 p-0">
                          <tr>
                            <td>
                              <span class="font-weight-bold">Sertifika :</span>
                              {{ education.certificate == 1 ? "Var" : "Yok" }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <span class="font-weight-bold"
                                >Eğitim Şekli:</span
                              >
                              {{
                                education.education_type == 1
                                  ? "Yüz Yüze"
                                  : education.education_type == 2
                                  ? "Online"
                                  : "Video"
                              }}
                            </td>
                          </tr>

                          <tr v-if="education.education_type != 3">
                            <td>
                              <span class="font-weight-bold"
                                >Kalan Katılımcı:</span
                              >
                              {{ education.participant }} Kişi
                            </td>
                          </tr>
                          <tr v-if="education.education_type != 3">
                            <td>
                              <span class="font-weight-bold">Bitiş :</span>
                              {{ education.end_date }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card" v-if="education.education_type == 3">
              <div class="card-header">
                <h3 class="card-title">Modüller</h3>
              </div>
              <div class="card-body">
                <div
                  class="panel-group1"
                  v-for="(item, i) in education.modules"
                  :key="'topList' + i"
                  :id="'accordion' + i"
                >
                  <h3 class="card-title">{{ item.title }}</h3>
                  <div
                    class="panel panel-default mb-4 border p-0"
                    v-for="(list, a) in item.sub_modules"
                    :key="'subList' + i + '_' + a"
                  >
                    <div class="panel-heading1">
                      <h4 class="panel-title1">
                        <a
                          class="accordion-toggle collapsed"
                          data-bs-toggle="collapse"
                          :data-parent="'#accordion' + i + '_' + a"
                          :href="'#collapse' + i + '_' + a"
                          aria-expanded="false"
                          >{{ list.title }}</a
                        >
                      </h4>
                    </div>
                    <div
                      :id="'collapse' + i + '_' + a"
                      class="panel-collapse collapse active"
                      role="tabpanel"
                      aria-expanded="false"
                    >
                      <div class="panel-body bg-white">
                        <p v-html="list.content"></p>
                        <template v-if="list.path">
                          <video controls>
                            <source
                              :src="
                                'https://storage.terapivitrini.com/' + list.path
                              "
                              type="video/mp4"
                            />
                          </video>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Comments :user_id="education.user_id" />
            <CommentWrite />

            <!--/Related Posts-->
          </div>

          <!--Right Side Content-->
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div id="navbar_ff">
              <div class="card">
                <div class="card-header">
                  <h3 class="card-title">Eğitmen</h3>
                </div>
                <div class="card-body item-user">
                  <div class="profile-pic mb-0">
                    <img
                      :src="
                        'https://storage.terapivitrini.com/' +
                        education.trainer[0].profile_image_url
                      "
                      class="brround avatar-xxl"
                      alt="user"
                    />
                    <div class="">
                      <router-link
                        :to="'/terapist/' + education.trainer[0].shortlink"
                        class="text-dark"
                        ><h4 class="mt-3 mb-1 font-weight-semibold">
                          {{ education.trainer[0].fullname }}
                        </h4></router-link
                      >
                    </div>
                  </div>
                </div>

                <div class="card-footer">
                  <div style="width: fit-content; margin: 0 auto">
                    <router-link
                      :to="'/terapist/' + education.trainer[0].shortlink"
                      class="btn btn-primary"
                      >Profili Görüntüle</router-link
                    >
                    <template v-if="!education.me">
                      <template v-if="!education.join">
                        <button
                          @click="joinEducation()"
                          class="btn btn-primary"
                          style="margin-left: 10px; width: 130px"
                          data-bs-toggle="modal"
                          data-bs-target="#contact"
                        >
                          Eğitime Katıl
                        </button>
                      </template>
                      <template v-if="education.join">
                        <button
                          class="btn btn-primary"
                          style="margin-left: 10px; width: 130px"
                        >
                          Eğitime Katıldın
                        </button>
                      </template>
                    </template>
                  </div>
                </div>
              </div>

              <div class="card">
                <div class="card-header">
                  <h4 class="mt-2">Diğer Eğitimler</h4>
                </div>
                <!--/Education Description-->

                <!--Related Posts-->
                <div id="myCarousel" class="owl-carousel owl-carousel-icons">
                  <div v-for="(item, i) in education.others" :key="i">
                    <Item3 :item="item" />
                    <template v-if="i == education.others.lenght">{{
                      run()
                    }}</template>
                  </div>
                </div>
              </div>
              <Item1 :item="education.ads" />
            </div>
          </div>
          <!--/Right Side Content-->
        </div>
      </div>
    </section>
  </div>
</template>
<style>
.owl-dots {
  display: none;
}
@media (min-width: 1280px) {
  .absolute {
    position: absolute;
    width: 377px;
  }
}
@media (max-width: 1280px) and (max-width: 990px) {
  .thumbimages {
    display: none;
  }
  .absolute {
    position: absolute;
    width: 296px;
  }
}
@media (max-width: 990px) {
  .absolute {
    display: block;
    position: initial;
    top: 0px;
    width: 100%;
  }
}

@media (max-width: 990px) {
  iframe {
    width: 100% !important;
  }
}
.contactItem {
  line-height: 45px;
}
.social-item {
  float: left;
  width: 40px;
  background: #f2f2f2;

  margin: 5px;
  padding: 5px;
  text-align: center;
  font-size: 20px;
  border-radius: 50px;
}
.social-item > a {
  color: #bb7494 !important;
}
@media (max-width: 990px) {
  .contactItem {
    line-height: 0px;
    padding-bottom: 15px;
  }
}
</style>
<script>
import Breadcrumb from "../../components/Breadcrumb.vue";
import Sidebar from "./Components/Sidebar.vue";
import Item3 from "./Components/Item3.vue";
import Comments from "./Components/Comments.vue";
import CommentWrite from "./Components/CommentWrite.vue";
import Item1 from "../../components/Ads/Item1.vue";
export default {
  metaInfo() {
    return {
      title: this.title,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.description,
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;

    let educationInfo = {
      id: this.$route.params.id,
    };
    this.$store.dispatch("getEducationGet", educationInfo).then((value) => {
      this.education = value[0];
      this.title = value[0].title+" - Terapi Vitrini";
      this.load = true;
      this.description =
        value[0].title +
        " Alanında uzman terapistin hazırlamış olduğu video, yüz yüze ve online eğitimlere erişim sağlamak için HEMEN ÜYE OLUN.";
      let _this = this;
      $(document).ready(function () {
        _this.image =
          "https://storage.terapivitrini.com/" + _this.education.image[0].path;
        let content = document.getElementById("appcontent").innerHTML;
        _this.$store.dispatch("setMetaContent", [
          _this.title,
          _this.description,
          "",
          "",
          _this.image,
          content,
        ]);
      });
    });

    setTimeout(() => {
      this.run();
    }, 1000);
  },
  data() {
    return {
      education: [],
      title: "",
      description: "",
      load: false,
      image:"",
    };
  },
  components: { Breadcrumb, Sidebar, Item3, Comments, CommentWrite, Item1 },
  methods: {
    joinEducation() {
      if (!this.$store.state.isLogin) {
        this.$router.push("/login");
      } else {
        /**Eğitime Katıl */
        let educationInfo = {
          id: this.$route.params.id,
        };
        this.$store
          .dispatch("getEducationJoin", educationInfo)
          .then((value) => {
            if (value.type == "error") {
              if (value.message == "ERRxOENJ") {
                this.$vToastify.warning(
                  "Kendi eğitimine katılamazsın",
                  "Uyarı!"
                );
              }
              if (value.message == "ERRxARJ") {
                this.$vToastify.warning("Eğitime zaten kayıtlısın", "Uyarı!");
              }
            }
            if (value.type == "success") {
              if (value.message == "SCCx001") {
                this.$vToastify.success(
                  "Eğitim kaydınız başarılı bir şekilde oluşturuldu",
                  "Başarılı!"
                );
                this.$router.push(
                  "/profile/education/" + value.id + "/pay/credit"
                );
              }
            }
          });
      }
    },
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    run() {
      (function ($) {
        /*---Owl-carousel----*/

        // ______________Owl-carousel-icons
        var owl = $(".owl-carousel-icons");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: true,
          autoplay: true,
          dots: false,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Owl-carousel-icons2
        var owl = $(".owl-carousel-icons2");
        owl.owlCarousel({
          loop: true,
          rewind: false,
          margin: 25,
          animateIn: "fadeInDowm",
          animateOut: "fadeOutDown",
          autoplay: false,
          autoplayTimeout: 5000, // set value to change speed
          autoplayHoverPause: true,
          dots: false,
          nav: true,
          autoplay: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
            600: {
              items: 2,
              nav: true,
            },
            1300: {
              items: 4,
              nav: true,
            },
          },
        });

        // ______________Owl-carousel-icons3
        var owl = $(".owl-carousel-icons3");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          dots: false,
          autoplay: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 2,
            },
          },
        });

        // ______________Owl-carousel-icons4
        var owl = $(".owl-carousel-icons4");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          dots: false,
          autoplay: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 3,
            },
            1000: {
              items: 6,
            },
          },
        });

        // ______________Owl-carousel-icons5
        var owl = $(".owl-carousel-icons5");
        owl.owlCarousel({
          loop: true,
          rewind: false,
          margin: 25,
          animateIn: "fadeInDowm",
          animateOut: "fadeOutDown",
          autoplay: false,
          autoplayTimeout: 5000, // set value to change speed
          autoplayHoverPause: true,
          dots: false,
          nav: false,
          autoplay: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
            600: {
              items: 2,
              nav: true,
            },
            1300: {
              items: 4,
              nav: true,
            },
          },
        });

        // ______________Owl-carousel-icons6
        var owl = $(".owl-carousel-icons6");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          dots: false,
          autoplay: true,
          responsive: {
            0: {
              items: 1,
            },
            600: {
              items: 2,
            },
            1000: {
              items: 3,
            },
          },
        });

        // ______________Testimonial-owl-carousel2
        var owl = $(".testimonial-owl-carousel2");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          autoplay: true,
          dots: false,
          animateOut: "fadeOut",
          smartSpeed: 450,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Testimonial-owl-carousel3
        var owl = $(".testimonial-owl-carousel3");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          autoplay: true,
          dots: false,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Testimonial-owl-carousel4
        var owl = $(".testimonial-owl-carousel4");
        owl.owlCarousel({
          margin: 25,
          loop: true,
          nav: false,
          autoplay: true,
          dots: false,
          responsive: {
            0: {
              items: 1,
            },
          },
        });

        // ______________Testimonial-owl-carousel
        var owl = $(".testimonial-owl-carousel");
        owl.owlCarousel({
          loop: true,
          rewind: false,
          margin: 25,
          autoplay: true,
          animateIn: "fadeInDowm",
          animateOut: "fadeOutDown",
          autoplay: false,
          autoplayTimeout: 5000, // set value to change speed
          autoplayHoverPause: true,
          dots: false,
          nav: true,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
              nav: true,
            },
          },
        });
      })(jQuery);
    },
  },
  mounted() {
    this.backToTop();
    this.run();

    window.onscroll = function () {
      var navbar = document.getElementById("navbar_ff");
      let slider = document.getElementById("promo_slider").offsetTop;
      let description = document.getElementById("commentWrite").offsetTop-navbar.offsetHeight;

      if (window.pageYOffset >= slider && window.pageYOffset <= description) {
        navbar.classList.add("absolute");
        navbar.style.top = window.pageYOffset - slider + "px";
      } else if (window.pageYOffset >= description) {
        //navbar.classList.remove("absolute");
      } else {
        navbar.classList.remove("absolute");
      }
    };
  },
};
</script>